<template>
<div>
    <div v-if="!showLoading" class="seperate-component">
        <div class="reward-wheel-animated-background">

        </div>
        <div class="reward-wheel-spin">
            <div class="adjust-padding text-center adjust-border-1">
            <p class="merchant-header-text">Exclusive CanPay Member Benefit</p>
            <div style="width:100%;display: flex; justify-content: center; align-items: center;" class="handle-svg-text mt-1">
            <div style="width:120px;overflow: hidden">
                <img style="object-fit:cover;object-position: center;height:100%;width:100%;" :src="image" alt="merchant logo">
            </div>
            </div>
            </div>
            <div class="reward-wheel-animation-body adjust-border-2">
                <p class="control-margin pt-4 merchant-desc-text">Save $88 on your Med Card</p>
                <p class="control-margin pb-4 merchant-desc-text">renewal from NuggMD.</p>
                <button class="learn-more-button" @click="redirectTo()">Learn More
                    <svg class="learn-more-arrow" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 25 42" fill="none">
                    <path d="M24.0047 19.3612L5.31415 0.67097C4.88186 0.23834 4.30479 0 3.68948 0C3.07417 0 2.4971 0.23834 2.06481 0.67097L0.688385 2.04706C-0.207266 2.94373 -0.207266 4.40109 0.688385 5.2964L16.3833 20.9913L0.67097 36.7036C0.238681 37.1362 0 37.713 0 38.3279C0 38.9436 0.238681 39.5203 0.67097 39.9533L2.0474 41.329C2.48003 41.7617 3.05676 42 3.67207 42C4.28738 42 4.86445 41.7617 5.29674 41.329L24.0047 22.6218C24.438 22.1878 24.676 21.6083 24.6746 20.9923C24.676 20.3739 24.438 19.7948 24.0047 19.3612Z" fill="white"/>
                    </svg>
                </button>
                <p class="merchant-header-text pb-3 pt-1" style="text-decoration:underline;" @click="initiateAnimation()">I will check it out later. Spin the Wheel.</p>   
            </div>
        </div>
        <div  class="reward-wheel-spin-display">
            <div class="cover-the-background adjust-border-3">
            </div>
            <div v-if="showImage" style="width:100%;display: flex; justify-content: center; align-items: center;" class="handle-svg-text-1 mt-1">
            <div class="setWidthForRewardWheelSpinDisplay">
                <img style="object-fit:cover;object-position: center;height:100%;width:100%;" :src="image" alt="">
            </div>
            </div>
        </div>
        <div v-if="!showImage" class="div-style-image">
            <div class="setWidthForRewardWheelSpinDisplay">
                <img style="object-fit:cover;object-position:center;height:100%;width:100%;" :src="image" alt="">
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
name:"RewardWheelNuggMdAnimatedModal",
props:{
    commandOverSpinValue:{
        type:Function,
        require:true
    },
    showLoading:{
        type:Boolean,
        required:true
    },
    coupon_code:{
        type:String
    },
    image:{
        type:String
    },
    showButton:{
        type:Function
    }
},
data(){
    return {
        startRewardWheelModalTiming:1000,
        startSvgAnimationTiming:2000,
        startShrinkAnimation:2000,
        startSmaalShrinkAnimationTime:2000,
        startSlowTranslateYAnimationTime:2000,
        showImage:true
    }
},
mounted(){
},
methods:{
    initiateAnimation(){
        let self = this;
        self.showButton();
        const startRewardWheelModalAnimation = document.querySelector(".reward-wheel-spin");
        const rewardWheelAnimationBody = document.querySelector(".reward-wheel-animation-body");
        const removeSeparateComponent = document.querySelector(".seperate-component");
        startRewardWheelModalAnimation.style.animation = `startRewardModalAnimation ${self.startRewardWheelModalTiming}ms linear alternate infinite`;
        setTimeout(()=>{
            startRewardWheelModalAnimation.style.animationPlayState = 'paused';
            const startSvgAnimation = document.querySelector(".handle-svg-text-1");
            startSvgAnimation.style.animation = `merchant-svg-frame ${self.startSvgAnimationTiming}ms linear alternate infinite`;
            setTimeout(()=>{
                startSvgAnimation.style.animationPlayState = 'paused';
                const startRewardWheelSpinDisplayAnimation = document.querySelector(".cover-the-background");
                setTimeout(()=>{
                    startRewardWheelSpinDisplayAnimation.style.borderTop = "4px solid #ffffff";
                    startRewardWheelSpinDisplayAnimation.style.borderBottom = "4px solid #ffffff";
                },50);
                startRewardWheelSpinDisplayAnimation.style.animation = `startShrinkAnimation ${self.startShrinkAnimation}ms linear alternate infinite`;
                setTimeout(()=>{
                    self.showImage = false;
                    startRewardWheelSpinDisplayAnimation.style.animationPlayState = 'paused';
                    const startShrinkModalAnimation = document.querySelector(".reward-wheel-spin-display");
                    setTimeout(()=>{
                        startRewardWheelSpinDisplayAnimation.style.borderTop = "9px solid #ffffff";
                        startRewardWheelSpinDisplayAnimation.style.borderBottom = "9px solid #ffffff";
                    },10);
                    startShrinkModalAnimation.style.animation = `startSmallShrinkAnimation ${self.startSmaalShrinkAnimationTime}ms linear alternate infinite`
                    setTimeout(()=>{
                        startRewardWheelSpinDisplayAnimation.style.border = "none";
                        startShrinkModalAnimation.style.animationPlayState = 'paused';
                        setTimeout(()=>{
                        const setWidthForRewardWheelSpinDisplay = document.querySelector(".setWidthForRewardWheelSpinDisplay");
                        setWidthForRewardWheelSpinDisplay.style.backgroundColor = "#153458";
                        setWidthForRewardWheelSpinDisplay.style.padding = "7px 10px";
                        setWidthForRewardWheelSpinDisplay.style.borderRadius = "10px";
                        setWidthForRewardWheelSpinDisplay.style.width = "140px";
                        setWidthForRewardWheelSpinDisplay.style.border = "2px solid #ffffff";
                        startShrinkModalAnimation.style.backgroundColor = "rgba(0,0,0,0.0)";
                        rewardWheelAnimationBody.style.backgroundColor = "rgba(0,0,0,0.0)";
                        startRewardWheelSpinDisplayAnimation.style.backgroundColor = "rgba(0,0,0,0.0)"
                        },1);
                        const moveImageAnimation = document.querySelector(".setWidthForRewardWheelSpinDisplay");
                        moveImageAnimation.style.animation = `startTranslateYAnimation ${self.startSmaalShrinkAnimationTime}ms linear alternate infinite`
                        startShrinkModalAnimation.style.animation = `startTranslateYAnimation ${self.startSmaalShrinkAnimationTime}ms linear alternate infinite`
                        setTimeout(()=>{
                            moveImageAnimation.style.animationPlayState = 'paused';
                            removeSeparateComponent.style.height = "0vh";
                            startShrinkModalAnimation.style.position = "fixed";
                            // startRewardWheelModalAnimation.style.padding = "0px";
                            const transformScale = document.querySelector(".reward-wheel-animated-background");
                            transformScale.style.transform = "scale(0.1)";
                            startRewardWheelModalAnimation.style.transform = "scale(0.1)";
                            // diplayBackgroundNone.style.display = "none";
                            // removeSeparateComponent.style.position = "relative";
                            // removeSeparateComponent.style.overflowY = "hidden";
                            this.commandOverSpinValue(true);
                            startShrinkModalAnimation.style.height = "1px"
                            startShrinkModalAnimation.style.animationPlayState = 'paused'
                        },self.startSlowTranslateYAnimationTime)
                    },self.startSmaalShrinkAnimationTime)
                },self.startShrinkAnimation)
            },self.startSvgAnimationTiming/2)
        },self.startRewardWheelModalTiming)
    },
    redirectTo(){
        let self = this;
        self.$root.$emit("rewardWheelPageColor", {
            image: "",
            is_image: false,
            color_1: "",
            color_2: "",
            color_3: ""
        });
        localStorage.setItem("coupon_code",self.coupon_code)
        self.$router.push({path: "/memberbenefit", params: { coupon_code: self.coupon_code }});
    }
}
}
</script>
<style scoped>
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-29.6rem;
    z-index:9999999;
}
.reward-wheel-animated-background{
    background-color: rgba(0, 0, 0, 0);
    width:100vw;
    height:100vh;
}
.seperate-component{
    z-index:999999999;
    position:absolute;
    top:0px;
}

.merchant-header-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:15px;
    margin:0px;
}
.adjust-padding{
    padding: 10px 0px 16px 0px;
    background-color:#2c4869;
    border-radius: 12px 12px 0px 0px;
}
.handle-svg-text{
    position: relative;
    top:6px;
}

.reward-wheel-animation-body{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:20px;
    margin:0px;
    background-color:#153458;
    border-radius:0px 0px 12px 12px;
}
.control-margin{
    margin-bottom:0px;
}
/* CSS */
.learn-more-button {
  align-items: center;
  appearance: none;
  width:200px;
  background-color: #d65e67;
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  padding:5px 0px 5px 0px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd,sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.learn-more-button:active,
.learn-more-button:focus {
  outline: none;
}

.learn-more-button:hover {
  background-position: -20px -20px;
}

.learn-more-button:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}
.learn-more-arrow{
    position: absolute;
    right:15px;
}
.merchant-header-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:0.8rem;
    margin-top:5px;
}
@media only screen and (min-width: 343px) and (max-width: 359px){
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-230px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 30px 0px 30px;
    width:100%;
    top:31%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:31vh;
     border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 30px 0px 30px;
    top:31%;
    border-radius:12px;
}
}
@media only screen and (min-width: 359px) and (max-width:374px) and (max-height:641px) and (max-height:780px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-22.5rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-225px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 30px 0px 30px;
    width:100%;
    top:38%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:35vh;
    border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 30px 0px 30px;
    top:38%;
    border-radius:12px;
}
}
@media only screen and (min-width: 359px) and (max-width:374px) and (max-height:641px) and (min-height:780px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-13.5rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-225px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 30px 0px 30px;
    width:100%;
    top:47%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:42vh;
    border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 30px 0px 30px;
    top:47%;
    border-radius:12px;
}
}
@media only screen and (min-width: 359px) and (max-width:374px) and (min-height:641px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-20.9rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-225px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 30px 0px 30px;
    width:100%;
    top:37%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:36vh;
    border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 30px 0px 30px;
    top:37%;
    border-radius:12px;
}
}
@media only screen and (min-width: 374px) and (max-width: 389px) and (min-height:668px) and (max-height:813px){
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-226px;
}
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-25rem;
    z-index:9999999;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 30px 0px 30px;
    width:100%;
    top:34%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:0.99rem; 
    font-weight:600;
}
.cover-the-background{
    background-color:#153458;
    height:33vh;
     border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 30px 0px 30px;
    top:34%;
    border-radius:12px;
}
}
@media only screen and (min-width: 374px) and (max-width: 389px) and (min-height:667px) and (max-height:813px){
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-226px;
}
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-14rem;
    z-index:9999999;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 30px 0px 30px;
    width:100%;
    top:47%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:0.99rem; 
    font-weight:600;
}
.cover-the-background{
    background-color:#153458;
    height:40vh;
     border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 30px 0px 30px;
    top:47%;
    border-radius:12px;
}
}

@media only screen and (min-width: 374px) and (max-width: 389px) and (min-height:813px){
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-226px;
}
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-14.5rem;
    z-index:9999999;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 30px 0px 30px;
    width:100%;
    top:45%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:0.99rem; 
    font-weight:600;
}
.cover-the-background{
    background-color:#153458;
    height:40vh;
     border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 30px 0px 30px;
    top:45%;
    border-radius:12px;
}
}
@media only screen and (min-width: 389px) and (max-width:411px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-25.2rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-230px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 30px 0px 30px;
    width:100%;
    top:37%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:32vh;
     border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 30px 0px 30px;
    top:37%;
    border-radius:12px;
}
}
@media only screen and (min-width: 411px) and (max-width:413px) and (max-height:914px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-18.9rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-230px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 50px 0px 50px;
    width:100%;
    top:42%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:35vh;
    border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 50px 0px 50px;
    top:42%;
    border-radius:12px;
}
}
@media only screen and (min-width: 411px) and (max-width:413px) and (min-height:914px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-29.6rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-230px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 50px 0px 50px;
    width:100%;
    top:34%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:29.6vh;
    border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 50px 0px 50px;
    top:34%;
    border-radius:12px;
}
}
@media only screen and (min-width: 413px) and (max-width:429px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-28.3rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-227px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 50px 0px 50px;
    width:100%;
    top:35%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:30vh;
     border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 50px 0px 50px;
    top:35%;
    border-radius:12px;
}
}
@media only screen and (min-width: 429px) and (max-width:539px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-29.6rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-235px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 50px 0px 50px;
    width:100%;
    top:35%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
     background-color:#153458;
     height:29.3vh;
     border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 50px 0px 50px;
    top:35%;
    border-radius:12px;
}
}
@media only screen and (min-width: 539px) and (max-width:767px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-15.3rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-235px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 70px 0px 70px;
    width:100%;
    top:47%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:40vh;
     border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 70px 0px 70px;
    top:47%;
    border-radius:12px;
}
}
@media only screen and (min-width: 767px) and (max-width:911px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-31.9rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-233px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 12rem 0px 12rem;
    width:100%;
    top:37%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:26.7vh;
    border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 12rem 0px 12rem;
    top:37%;
    border-radius:12px;
}
}
@media only screen and (min-width: 819px) and (max-width: 852px) and (min-height:1179px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-42.3rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-235px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 230px 0px 230px;
    width:100%;
    top:32%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:23vh;
    border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 230px 0px 230px;
    top:32%;
    border-radius:12px;
}
}
@media only screen and (min-width: 852px) and (max-width: 911px) and (min-height:1179px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-48.2rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-235px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 220px 0px 220px;
    width:100%;
    top:30%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:21vh;
    border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 220px 0px 220px;
    top:30%;
    border-radius:12px;
}
}
@media only screen and (min-width: 911px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-54.3rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-230px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 17rem 0px 17rem;
    width:100%;
    top:27%;
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:20vh;
     border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:444444;
    position:absolute;
    width:100%;
    padding:0px 17rem 0px 17rem;
    top:27%;
    border-radius:12px;
}
}
@media only screen and (min-width: 1023px) and (max-height:600px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-10.6rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-230px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 350px 0px 350px;
    width:100%;
    top:50%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:45vh;
     border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 350px 0px 350px;
    top:50%;
    border-radius:12px;
}
}
@media only screen and (min-width: 1023px) and (min-height:1365px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-57.6rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-230px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 300px 0px 300px;
    width:100%;
    top:23%;
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:19.5vh;
     border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 300px 0px 300px;
    top:23%;
    border-radius:12px;
}
}

@media only screen and (min-width: 1280px){

.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-21.6rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-230px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 450px 0px 450px;
    width:100%;
    top:40%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:34vh;
     border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 450px 0px 450px;
    top:40%;
    border-radius:12px;
}
}
@media only screen and (min-width: 1300px){
.div-style-image{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
    top:-18.6rem;
    z-index:9999999;
}
.handle-svg-text-1{
    position: relative;
    z-index:44445;
    top:-230px;
}
.reward-wheel-spin{
    z-index:9999999;
    position:absolute;
    padding:0px 580px 0px 580px;
    width:100%;
    top:42%;
    
}
.merchant-desc-text{
    font-family:'Open Sans';
    color:#ffffff;
    font-size:1.1rem;
    font-weight:600; 
}
.cover-the-background{
    background-color:#153458;
    height:35vh;
     border-radius:12px;
}
.reward-wheel-spin-display{
    z-index:44444;
    position:absolute;
    width:100%;
    padding:0px 580px 0px 580px;
    top:42%;
    border-radius:12px;
}
}
.adjust-border-1{
    border-top: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
}
.adjust-border-2{
    border-bottom: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
}
.adjust-border-3{
    border: 2px solid #ffffff;
}
.setWidthForRewardWheelSpinDisplay{
    width:120px;
    overflow: hidden;
}
</style>
