<template>
<div>
    <div v-if="isLoading && wheelFound">
        <CanPayLoader/>
    </div>
    <div class="container pt-3 rw-body">
        <div class="text-white" style="height: calc(100vh - 120px)!important;" v-if="!isLoading && !wheelFound">
            Wheel Not Found!
        </div>
        <div>
            <div :style="isLoading == false ? 'display: block;' : 'display: none;'" class="rw-container rw-margin-bottom" v-if="hasJackpot" >
                <Jackpot
                style="position: relative; z-index: 2;"
                :jackpot-value="jackpot_value"
                :per-sec-value="jackpot_incr_per_second"
                />
            </div>
            <div :style="isLoading == false ? 'display: block;' : 'display: none;'" class="rw-container position-relative mb-2">
                <div v-if="rwPagination.total!=0 && wheelData[rwPagination.page].coupon_enabled == 1" style="padding-right:10px;">
                    <RewardWheelNuggMdCustomModal :image="custom_wheel_options.header.header_title_image" :showButton="showButton" :commandOverSpinValue="commandOverSpinValue"/>
                </div>
                <button 
                :style="rwPointButtonStyle"
                class="rw-timmer-chip">
                    <svg width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="20 20 530 530" style="enable-background:new 0 0 570.4 570.4;" xml:space="preserve">
                
                    <g>
                    <path d="M285.4,286c-12.9,12.8-25.7,25.6-38.6,38.4c-45.7,45.7-91.5,91.4-137.2,137.2c-1.5,1.5-3.1,2.9-5.3,4.9
                    c-6.9-8-13.9-15.4-20.1-23.4c-31.9-40.7-49.9-86.8-54-138.4c-0.2-1.6-0.5-3.2-0.9-4.8v-15c85.4,0,170.7,0,256.1-0.1L285.4,286z"/>
                    <path d="M541.4,284.9c-85,0-170.1,0-255.1-0.1c-0.3,0-0.7,0.1-1,0.1c1.3-1.5,2.5-3.1,3.9-4.5c57.5-57.5,115-115,172.5-172.5
                    c1.3-1.3,2.6-2.5,4.6-4.3c5.8,6.6,11.7,12.6,17,19.2c31.7,39,50.6,83.4,56.2,133.4c0.6,5.3,1.3,10.5,1.9,15.7L541.4,284.9z"/>
                    <path class="st1" d="M466.1,103.3C406,163.4,345.8,223.5,285.7,283.6c-0.2,0.2-0.4,0.5-0.6,0.8c-0.2-2-0.5-4-0.5-6
                    c0-81.3,0-162.6,0-243.9c0-1.8,0.1-3.6,0.2-6.3c8.8,0.5,17.2,0.7,25.6,1.5c50,5.1,94.7,23.2,134,54.6c4.1,3.3,8.3,6.5,12.5,9.8
                    L466.1,103.3z"/>
                    <path class="st2" d="M103.8,103.3c60.1,60.1,120.2,120.3,180.3,180.4c0.2,0.2,0.5,0.4,0.8,0.6c-2,0.2-4,0.5-6,0.5
                    c-81.3,0-162.6,0-243.9,0c-1.8,0-3.6-0.1-6.3-0.2c0.5-8.8,0.7-17.2,1.5-25.6c5.1-50,23.2-94.7,54.6-134c3.3-4.1,6.5-8.3,9.8-12.5
                    L103.8,103.3z"/>
                    <path class="st1" d="M104.3,467c60.1-60.1,120.3-120.2,180.4-180.3c0.2-0.2,0.4-0.5,0.6-0.8c0.2,2,0.5,4,0.5,6
                    c0,81.3,0,162.6,0,243.9c0,1.8-0.1,3.6-0.2,6.3c-8.8-0.5-17.2-0.7-25.6-1.5c-50-5.1-94.7-23.2-134-54.6c-4.1-3.3-8.3-6.5-12.5-9.8
                    L104.3,467z"/>
                    <path class="st2" d="M466.5,466.3C406.4,406.2,346.3,346,286.3,285.8c-0.2-0.2-0.5-0.4-0.8-0.6c2-0.2,4-0.5,6-0.5
                    c81.3,0,162.6,0,243.9,0.1c1.8,0,3.6,0.1,6.3,0.2c-0.5,8.8-0.7,17.2-1.5,25.6c-5.1,50-23.2,94.7-54.6,134
                    c-3.3,4.1-6.5,8.3-9.8,12.5L466.5,466.3z"/>
                    <path class="st3" d="M284.5,28.3c0,85,0,170.1-0.1,255.1c0,0.3,0.1,0.7,0.1,1c-1.5-1.3-3.1-2.5-4.5-3.9
                    C222.5,223,165,165.5,107.5,108c-1.3-1.3-2.5-2.6-4.3-4.6c6.6-5.8,12.6-11.7,19.2-17c39-31.7,83.4-50.6,133.4-56.2
                    c5.3-0.6,10.5-1.3,15.7-1.9H284.5z"/>
                    <path class="st4" d="M285.4,540.9c-0.1-1.7-0.2-3.3-0.2-5c0-81.9,0-163.8,0-245.6c0-1.5,0.1-3,0.2-4.5c1.1,0.9,2.4,1.8,3.4,2.8
                    c58,57.9,115.9,115.9,173.8,173.8c0.9,0.9,1.8,1.9,3,3.3c-1,1.1-1.9,2.3-2.9,3.3c-37.9,36.2-82.5,59-134.2,67.7
                    c-11,1.8-22.1,2.8-33.1,4.2L285.4,540.9z"/>
                    </g>
                    </svg>
                    <span class="d-flex align-items-center ml-1" v-html="availableSpinHTML"></span>
                </button>

                <div class="sparkle-container" :style="isLoading == false ? 'display: block;' : 'display: none;'">
                    <Wheel
                    class="winwheel-element"
                    v-model="spinning"
                    :wheel-data="wheelDivisionsData" 
                    :wheel-custom-options="custom_wheel_options" 
                    :wheel-id="wheel_id" 
                    :available-spin-count="spin_available_count"
                    :user-spin-result-id="userSpinResultID"
                    :bank-alert-model="showModal"
                    :callTheSpin="startSpining"
                    />
                </div>

                <div v-show="rwPagination.total > 0" class="rw-pagination-card">
                    <button v-if="spinning === false" @click="rwPrev()" v-show="rwPagination.prev" class="rw-pagination-btn prev col-1">
                        <svg width="20" viewBox="0 0 35 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M34.2661 4.68085L29.5853 4.09213e-07L3.00474 24.9088C2.0017 25.5775 0.998662 26.4134 0.998662 27.4164C0.998663 28.4195 2.0017 29.2553 2.83757 29.924L29.5853 55L34.2661 50.3191L9.85884 27.5836L34.2661 4.68085Z" fill="white"/>
                        </svg>
                    </button>
                    <div class="col-1" v-if="spinning === false"  v-show="!rwPagination.prev"></div>
                    <div v-if="spinning === false && (reward_wheel_button.wheel_bottom_button_text =='' || reward_wheel_button.wheel_bottom_button_text == null) && (reward_wheel_button.wheel_bottom_button_text_size == '' || reward_wheel_button.wheel_bottom_button_text_size == null)" class="rw-merchant-name col-10 d-flex align-items-center justify-content-center px-0">{{reward_wheel_title}}</div>
                    <div class="col-10" v-if="enable_button && !spinning && (reward_wheel_button.wheel_bottom_button_text !='' || reward_wheel_button.wheel_bottom_button_text !=null) && (reward_wheel_button.wheel_bottom_button_text_size != '' || reward_wheel_button.wheel_bottom_button_text_size != null)" style="width:100%; margin-top:19px;"><button class="custom-reward-wheel-button-to-wheel" :style="buttonStyle" role="button" @click="redirectToMemberBenefit">{{formatTheTextOfButton(reward_wheel_button)}}&nbsp;&nbsp;
                    <svg style="position:absolute;right:3%;" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 25 42" :fill="reward_wheel_button.wheel_bottom_button_background_color">
                    <path d="M24.0047 19.3612L5.31415 0.67097C4.88186 0.23834 4.30479 0 3.68948 0C3.07417 0 2.4971 0.23834 2.06481 0.67097L0.688385 2.04706C-0.207266 2.94373 -0.207266 4.40109 0.688385 5.2964L16.3833 20.9913L0.67097 36.7036C0.238681 37.1362 0 37.713 0 38.3279C0 38.9436 0.238681 39.5203 0.67097 39.9533L2.0474 41.329C2.48003 41.7617 3.05676 42 3.67207 42C4.28738 42 4.86445 41.7617 5.29674 41.329L24.0047 22.6218C24.438 22.1878 24.676 21.6083 24.6746 20.9923C24.676 20.3739 24.438 19.7948 24.0047 19.3612Z" fill="white"/>
                    </svg>
                    </button></div>
                    <button v-if="spinning === false" @click="rwNext()" v-show="rwPagination.next" class="rw-pagination-btn next col-1">
                        <svg width="20" viewBox="0 0 34 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.00012207 50.3191L4.68097 55L31.2615 30.0912C32.2645 29.4225 33.2676 28.5866 33.2676 27.5836C33.2676 26.5805 32.2645 25.7447 31.4287 25.076L4.68097 0L0.00012207 4.68085L24.4074 27.4164L0.00012207 50.3191Z" fill="white"/>
                        </svg>
                    </button>
                    <div class="col-1" v-if="spinning === false"  v-show="!rwPagination.next"></div>

                    <button v-if="spinning === true" v-show="rwPagination.prev" :class="spinning ? 'rw-pagination-btn prev disabled col-1' : 'rw-pagination-btn prev col-1'">
                        <svg width="20" viewBox="0 0 35 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M34.2661 4.68085L29.5853 4.09213e-07L3.00474 24.9088C2.0017 25.5775 0.998662 26.4134 0.998662 27.4164C0.998663 28.4195 2.0017 29.2553 2.83757 29.924L29.5853 55L34.2661 50.3191L9.85884 27.5836L34.2661 4.68085Z" fill="white"/>
                        </svg>
                    </button>
                    <div class="col-1" v-if="spinning === true" v-show="!rwPagination.prev"></div>
                    <div v-if="spinning === true && (reward_wheel_button.wheel_bottom_button_text =='' || reward_wheel_button.wheel_bottom_button_text == null) && (reward_wheel_button.wheel_bottom_button_text_size == '' || reward_wheel_button.wheel_bottom_button_text_size == null)" class="rw-merchant-name col-10 d-flex align-items-center justify-content-center px-0">{{reward_wheel_title}}</div>
                    <div class="col-10" v-if="enable_button && spinning && (reward_wheel_button.wheel_bottom_button_text !='' || reward_wheel_button.wheel_bottom_button_text !=null) && (reward_wheel_button.wheel_bottom_button_text_size != '' || reward_wheel_button.wheel_bottom_button_text_size != null)" style="width:100%; margin-top:19px;"><button class="custom-reward-wheel-button-to-wheel" :style="buttonStyle" role="button" @click="redirectToMemberBenefit">{{formatTheTextOfButton(reward_wheel_button)}}&nbsp;&nbsp;
                    <svg style="position:absolute;right:3%;" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 25 42" :fill="reward_wheel_button.wheel_bottom_button_background_color">
                    <path d="M24.0047 19.3612L5.31415 0.67097C4.88186 0.23834 4.30479 0 3.68948 0C3.07417 0 2.4971 0.23834 2.06481 0.67097L0.688385 2.04706C-0.207266 2.94373 -0.207266 4.40109 0.688385 5.2964L16.3833 20.9913L0.67097 36.7036C0.238681 37.1362 0 37.713 0 38.3279C0 38.9436 0.238681 39.5203 0.67097 39.9533L2.0474 41.329C2.48003 41.7617 3.05676 42 3.67207 42C4.28738 42 4.86445 41.7617 5.29674 41.329L24.0047 22.6218C24.438 22.1878 24.676 21.6083 24.6746 20.9923C24.676 20.3739 24.438 19.7948 24.0047 19.3612Z" fill="white"/>
                    </svg>
                    </button></div>
                    <button v-if="spinning === true" v-show="rwPagination.next" :class="spinning ? 'rw-pagination-btn next disabled col-1' : 'rw-pagination-btn next col-1'">
                        <svg width="20" viewBox="0 0 34 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.00012207 50.3191L4.68097 55L31.2615 30.0912C32.2645 29.4225 33.2676 28.5866 33.2676 27.5836C33.2676 26.5805 32.2645 25.7447 31.4287 25.076L4.68097 0L0.00012207 4.68085L24.4074 27.4164L0.00012207 50.3191Z" fill="white"/>
                        </svg>
                    </button>
                    <div class="col-1" v-if="spinning === true" v-show="!rwPagination.next"></div>
                </div>
            </div>

            <div :style="rpPointCardStyle" class="rw-my-points-card d-flex justify-content-center align-items-center">
                <p class="d-flex justify-content-center align-items-center">Your Reward Points Balance &nbsp;&nbsp;&nbsp;&nbsp; <span>{{pointNumberFormatter(available_reward_points).toLocaleString()}}</span></p>
            </div>
        </div>
        <!-- bank alert modal when consumer all banks are delinked -->
        <bank-alert-modal :wheelErrorMessage="wheelErrorMessage" ref="BankAlertModal"></bank-alert-modal>
        
    </div>
</div>
</template>

<script>
import Wheel from "./Wheel.vue";
import Jackpot from "./Jackpot.vue";
import api from "../../api/rewardwheel.js";
import reward_api from "../../api/rewardwheel.js";
import Loading from "vue-loading-overlay";
import BankAlertModal from "./BankAlertModal.vue";
import $store from '../../store'
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
import RewardWheelNuggMdAnimatedModal from "../Modal/RewardWheelNuggMdAnimatedModal.vue"
import RewardWheelNuggMdCustomModal from "../Modal/RewardWheelNuggMdCustomModal.vue"
export default {
    components:{
        Wheel,
        Jackpot,
        Loading,
        BankAlertModal,
        CanPayLoader,
        RewardWheelNuggMdAnimatedModal,
        RewardWheelNuggMdCustomModal
    },
    data(){
        return{
            wheel_id: null,
            available_reward_points: null,
            spin_available_count: null,
            wheelDivisionsData: [],
            wheelData: [],
            jackpot_incr_per_second: '0.00',
            jackpot_value: 0,
            reward_wheel_title: "",
            reward_wheel_button:{
                wheel_bottom_button_background_color:"",
                wheel_bottom_button_text:"",
                wheel_bottom_button_text_color:"",
                wheel_bottom_button_text_size:""
            },
            rwPagination: {
                total: 0,
                page: 0,
                prev: false,
                next: false
            },
            isLoading: true,
            wheelFound: true,
            spinning: false,
            hasJackpot: false,
            availableSpinHTML: '',
            userSpinResultID: '',
            custom_wheel_options: {},
            sparkles_show: false,
            sparklesGenerated: false,
            wheelErrorMessage: '',
            startSpining:false,
            timingAfterStart:0,
            enable_button:false
        }
    },
    watch:{
        isLoading: function(newVal, oldVal){
            let self = this;
            if(newVal == true){
                self.enable_button = false;
            }
        }
    },
    computed: {
        buttonStyle() {
            return {
                backgroundColor: this.reward_wheel_button.wheel_bottom_button_background_color,
                color: this.reward_wheel_button.wheel_bottom_button_text_color,
                fontSize: this.reward_wheel_button.wheel_bottom_button_text_size,
            };
        },
        rwPointButtonStyle(){
            if(this.custom_wheel_options && this.custom_wheel_options.spin_button){
                if(this.custom_wheel_options.spin_button.spin_transparent){
                    return 'background-color:'+ this.custom_wheel_options.spin_button.color + '96!important; padding: 1px 1px;';
                }else{
                    return 'background-color:'+ this.custom_wheel_options.spin_button.color + '!important; padding: 1px 1px;';
                }
            }else{
                return ''
            }
        },
        rpPointCardStyle(){
            if(!this.isLoading){
                if(this.custom_wheel_options && this.custom_wheel_options.footer){
                    if(this.custom_wheel_options.footer.footer_transparent){
                        return 'display: flex!important; background-color:'+ this.custom_wheel_options.footer.color + '96!important;'
                    }else{
                        return 'display: flex!important; background-color:'+ this.custom_wheel_options.footer.color + '!important;'
                    }
                }else{
                    return 'display: flex!important;'
                }
            }else{
                return 'display: none!important;'
            }
        }
    },
    methods:{
        redirectToMemberBenefit(){
            let self = this;
            self.$router.push("/memberbenefit");
        },
        formatTheTextOfButton(button_obj){
            return button_obj.wheel_bottom_button_text;
        },
        commandOverSpinValue(start_spin){
            let self = this;
            self.startSpining = start_spin;
        },
        getConsumerBankStatus(){
            reward_api.getConsumerBankStatus()
                .then((response)=>{
                const user_any_active_bank_account = response.data;
                if(user_any_active_bank_account){
                    self.wheelErrorMessage = response.message
                    self.showModal();
                }
                })
                .catch((error) => {
                    error(error.response.data.message);
                }) 

        },
        showButton(){
            let self = this;
            self.enable_button = !self.enable_button
        },
        getWheelData(){
            var self = this;
            api
            .getWheelData()
            .then((response) => {


                if(response.data.length <= 0){
                    self.$router.push('/lastThreeRewardHistory');
                    return;
                }

                self.wheelData = response.data
                self.wheelData.unshift(0);
                delete self.wheelData[0];

                if($store.getters.getRewardWheelID){
                    var paramWheelIndex = self.wheelData.map(function (wheel) { return wheel.id; }).indexOf($store.getters.getRewardWheelID);

                    if(paramWheelIndex < 0){
                        self.$router.push('/lastThreeRewardHistory');
                        return;
                    }

                    if(self.wheelData.length > 1){
                        self.rwPagination.total = self.wheelData.length-1
                        self.rwPagination.page = paramWheelIndex
                        self.rwPagination.prev = false
                        self.rwPagination.next = false
                    }
                    self.reward_wheel_title = self.wheelData[paramWheelIndex].reward_wheel
                    self.hasJackpot = self.wheelData[paramWheelIndex].has_jackpot === paramWheelIndex ? true : false
                    self.rwPaginationCalculation();
                    self.getWheelDivisionsData();
                    self.updateUserSpinResult();
                    self.wheelFound = true

                }else{
                    if(self.wheelData.length > 1){
                        self.rwPagination.total = self.wheelData.length-1
                        self.rwPagination.page = 1
                        self.rwPagination.prev = false
                        self.rwPagination.next = false
                    }
                    self.reward_wheel_title = self.wheelData[1].reward_wheel
                    self.hasJackpot = self.wheelData[1].has_jackpot === 1 ? true : false
                    self.rwPaginationCalculation();
                    self.getWheelDivisionsData();
                    self.updateUserSpinResult();
                    self.wheelFound = true
                }
                
            })
            .catch(function (error) {
                self.wheelFound = false
                self.isLoading = false;
            });
        },
        getWheelDivisionsData(){

            var self = this;
            self.isLoading = true;

            self.hasJackpot = self.wheelData[self.rwPagination.page].has_jackpot === 1 ? true : false
            self.reward_wheel_title = self.wheelData[self.rwPagination.page].reward_wheel
            api
            .getWheelDivisionsData({reward_wheel_id: self.wheelData[self.rwPagination.page].id})
            .then((response) => {

                self.rwPaginationCalculation()

                self.wheel_id = response.data.reward_wheel_id;
                self.available_reward_points = response.data.available_reward_points;
                self.spin_available_count = response.data.spin_available_count;
                self.wheelDivisionsData = response.data.reward_wheel_definitions;
                self.jackpot_incr_per_second = response.data.jackpot_incr_per_second;
                self.jackpot_value = parseInt(response.data.jackpot_value);

                var total_spin_html = '';
                if(parseInt(response.data.spin_available_count) >= 99){
                    total_spin_html = '99+';
                }else{
                    total_spin_html = 'x'+response.data.spin_available_count;
                }

                // Set Wheel Custom Options 
                self.custom_wheel_options = response.data.reward_wheel_design
                self.reward_wheel_button = self.custom_wheel_options.button;
                let spin_text_color = '#000';
                if(self.custom_wheel_options && self.custom_wheel_options.spin_button){
                    spin_text_color =  'color:'+ self.custom_wheel_options.spin_button.text_color + '!important;';
                }

                self.availableSpinHTML = '<span class="free-spin-text d-flex flex-column justify-content-center"><span style="'+spin_text_color+'">FREE</span><span style="'+spin_text_color+'">SPIN</span></span><span class="spin-value-text" style="background-color: #007EE5; font-size: 12px;">'+total_spin_html+'</span>';
                self.$root.$emit("spinAvailableCount", {spin_available_count: response.data.spin_available_count});
                self.$root.$emit("rewardAvailablePoint", {available_reward_points: response.data.available_reward_points});
                
                self.$root.$emit("rewardWheelHeaderTitle", {
                    header_type:self.custom_wheel_options.header.header_type, 
                    header_title_image:self.custom_wheel_options.header.header_title_image,
                    header_sub_title:self.custom_wheel_options.header.header_sub_title,
                    header_sub_title_size:self.custom_wheel_options.header.header_sub_title_size,
                    header_sub_title_color:self.custom_wheel_options.header.header_sub_title_color,
                    title: self.custom_wheel_options.header.page_title, 
                    size: self.custom_wheel_options.header.page_title_size});
                setTimeout(() => { // Use setimeout so that the image does not get loaded before the wheel is rendered
                self.$root.$emit("rewardWheelPageColor", {
                    image: self.custom_wheel_options.page.image,
                    is_image: self.custom_wheel_options.page.is_image,
                    color_1: self.custom_wheel_options.page.color_1,
                    color_2: self.custom_wheel_options.page.color_2,
                    color_3: self.custom_wheel_options.page.color_3
                });
                console.log("Delayed!!");
                }, "1000");
                
                self.sparkles_show = self.custom_wheel_options.sparkles.show
               
            })
            .catch(function (error) {
                self.isLoading = false;
            });

            reward_api.getConsumerBankStatus()
                .then((response)=>{
                const user_any_active_bank_account = response.data;
                if(user_any_active_bank_account){
                    self.wheelErrorMessage = response.message
                    self.showModal();
                }
                })
                .catch((error) => {
                    error(error.response.data.message);
                }) 

        },
        rwPaginationCalculation(){
            var self = this;
            if(self.rwPagination.page > 1){
                self.rwPagination.prev = true
            }else{
                self.rwPagination.prev = false
            }

            if(self.rwPagination.page < self.rwPagination.total){
                self.rwPagination.next = true
            }else{
                self.rwPagination.next = false
            }
        },
        rwPrev(){
            var self = this;
            if(!self.$online){
                return
            }
            self.removeStylesOnPageChange()
            self.getWheelDivisionsData(self.rwPagination.page--)
            self.updateUserSpinResult()
        },
        rwNext(){
            var self = this;
            if(!self.$online){
                return
            }
            self.removeStylesOnPageChange()
            self.getWheelDivisionsData(self.rwPagination.page++)
            self.updateUserSpinResult()
        },
        updateUserSpinResult(){
            var self = this;
            api
            .updateUserSpinResult({id: self.wheelData[self.rwPagination.page].id})
            .then(response => {
                self.userSpinResultID = response.data
            })
            .catch(function (error) {
            });
        },
        removeStylesOnPageChange(){
            var self = this;
            var className = "golden-sparkle"
            const elements = document.getElementsByClassName(className);
            while(elements.length > 0){
                elements[0].parentNode.removeChild(elements[0]);
            }
            self.$root.$emit("rewardWheelHeaderColor", {color: ""});
                self.$root.$emit("rewardWheelHeaderTitle", 
                {header_type:"" ,
                header_title_image:"",
                header_sub_title:"",
                header_sub_title_size:"",
                header_sub_title_color:"",
                title: "", 
                size: ""});
            self.$root.$emit("rewardWheelPageColor", {
                image: "",
                is_image: false,
                color_1: "",
                color_2: "",
                color_3: ""
            });
            self.sparklesGenerated = false;
            self.$root.$emit("rewardWheelLoader", {loading: true})
            self.custom_wheel_options = {}
        },
        // Function to generate multiple sparkling balls
        generateSparkles() {
            const container = document.querySelector(".sparkle-container");
            const numSparkles = 300; // Adjust the number of sparkles as desired
            const centerX = container.offsetWidth / 2;
            const centerY = container.offsetHeight / 2;
            const radius = Math.min(centerX, centerY) * 1.2; // Adjust the spread radius as desired

            for (let i = 0; i < numSparkles; i++) {
                const sparkle = this.createSparkle();

                // Randomly position the sparkles closer to the center
                const angle = Math.random() * 2 * Math.PI;
                const distance = Math.random() * radius;
                const x = centerX + distance * Math.cos(angle);
                const y = centerY + distance * Math.sin(angle);

                sparkle.style.left = x + "px";
                sparkle.style.top = y + "px";

                container.appendChild(sparkle);
            }
        },
        // Function to create a sparkling ball element with random size
        createSparkle() {
            const sparkle = document.createElement("div");
            sparkle.classList.add("golden-sparkle");

            // Randomly set the size of the sparkles
            const size = this.getRandomNumber(2, 6); // Adjust the minimum and maximum size as desired
            sparkle.style.width = size + "px";
            sparkle.style.height = size + "px";

            return sparkle;
        },
        // Function to generate a random number between min and max
        getRandomNumber(min, max) {
            return Math.random() * (max - min) + min;
        },

        showModal() {
            this.$bvModal.show("bank-alert-modal");
        },
        hideModal() {
             this.$bvModal.hide("bank-alert-modal");
            this.$router.push('/pay');
        },
        bankLinkPage(){
            this.$router.push('/banklinking');
        }
    },
    mounted(){
        let self = this;

        self.$root.$on("removeWheelDesignValues", function () {
            self.custom_wheel_options = {}
        });

        this.$root.$on("setWheelImageLoader", function (data) {
            if(self.custom_wheel_options.page.is_image){
                var imageContainer = document.getElementById("rewardWheelBody");
                var image = new Image();
                image.onload = function() {
                    self.isLoading = false;
                    imageContainer.style.backgroundImage = "url('" + image.src + "')";
                    self.$root.$emit("rewardWheelLoader", {loading: false})
                    self.$root.$emit("rewardWheelHeaderColor", {color: self.custom_wheel_options.header.color, transparent: self.custom_wheel_options.header.header_transparent});
                    if (self.sparkles_show && !self.sparklesGenerated) {
                        setTimeout(() => {
                            self.generateSparkles();
                        }, 500);
                        self.sparklesGenerated = true;
                    }
                };
                image.src = self.custom_wheel_options.page.image;
            }else{
                self.isLoading = false;
                self.$root.$emit("rewardWheelLoader", {loading: false})
                self.$root.$emit("rewardWheelHeaderColor", {color: self.custom_wheel_options.header.color, transparent: self.custom_wheel_options.header.header_transparent});
                if (self.sparkles_show && !self.sparklesGenerated) {
                    setTimeout(() => {
                        self.generateSparkles();
                    }, 500);
                    self.sparklesGenerated = true;
                }
            }
            
        });

        this.$root.$on("rewardAvailablePoint", function (data) {
            self.available_reward_points = data.available_reward_points;
        });

        this.$root.$on("setJackpotBaseline", function (data) {
            self.jackpot_value = data.jackpot_value;
        });

        this.$root.$on("spinAvailableCount", function (data) {
            self.spin_available_count = data.spin_available_count;
            var total_spin_html = '';
            if(parseInt(data.spin_available_count) >= 99){
                total_spin_html = '99+';
            }else{
                total_spin_html = 'x'+data.spin_available_count;
            }
            let spin_text_color = '#000';
            if(self.custom_wheel_options && self.custom_wheel_options.spin_button){
                spin_text_color =  'color:'+ self.custom_wheel_options.spin_button.text_color + '!important;';
            }
            self.availableSpinHTML = '<span class="free-spin-text d-flex flex-column justify-content-center"><span style="'+spin_text_color+'">FREE</span><span style="'+spin_text_color+'">SPIN</span></span><span class="spin-value-text" style="background-color: #007EE5; font-size: 12px;">'+total_spin_html+'</span>';
        });

        self.getWheelData();

        var element = document.getElementsByClassName("content-wrap");
        if (element[0]) {
            element[0].style.setProperty("background", "linear-gradient(3deg, #148f3f, #148f3f)");
            element[0].style.height = "114vh";
      if(window.innerWidth>1200){
        element[0].style.height = "121vh";
      }
        }
        this.$root.$emit("loginapp", [""]);
        this.$root.$emit("changeWhiteBackground", [
            false,
            false,
            "RewardWheelHeader"
        ]);
    },
}
</script>

<style scoped>
.font-style{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 15px;
}
.button-black{
    width:100%;
    padding: 15px 25px;
    background-color: #010002;
    color: snow;
    border-radius: 35px;
    font-size:15px;
    font-weight: 600;
    border:none;
}
.button-green{
     width:100%;
    padding: 15px 25px;
    background-color: #149240;
    color:snow;
    border-radius: 35px;
    font-size:15px;
    font-weight: 600;
    border:none;
}
.container{
    padding-right: 0;
    padding-left: 0;
}
.rw-body{
    overflow: hidden;
}
</style>
<style>
.rw-container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.rw-text{
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.rw-content{
    font-size: 14px;
    color: #fff;
    font-size: calc(11px + (20 - 11) * ((100vw - 320px) / (1920 - 320)));
}
.rw-content a, .rw-content a:hover{
    font-size: 16px;
    text-decoration: underline;
    color: #fff;
    font-size: calc(11px + (20 - 11) * ((100vw - 320px) / (1920 - 320)));
}
.rw-my-points-card{
    padding: 18px 10px;
    color: #fff;
    margin-bottom: 7px;
}
.rw-my-points-card p{
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 600;
}
.rw-my-points-card p span{
    font-size: 24px;
    font-weight: bold;
    vertical-align: middle;
}

.rw-pagination-card{
    position: relative;
    min-height: 70px;
    display: flex;
    flex-flow: row;
}
.rw-pagination-btn{
    background: transparent;
    border: 0;
}
.rw-merchant-name{
    font-weight: 600;
    color: white;
    font-size: 25px;
}
.rw-pagination-btn.disabled{
    cursor: no-drop;
}
.rw-margin-bottom{
    margin-bottom: 7px;
}


/* SPARKLES  */
.golden-sparkle {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fffff2;
    box-shadow: 0 0 20px 3px #ffffd8;
    /* animation: sparkleAnimation 5s infinite; */
}

.rw-winprize-modal{
  background-color: #ffffff;
}
#bank-alert-modal___BV_modal_body_{
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
.rw-winprize-modal-body{
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
}

@keyframes sparkleAnimation {
    0% {
    transform: scale(0);
    opacity: 0;
    }
    50% {
    transform: scale(1.5);
    opacity: 1;
    box-shadow: 0 0 20px 5px #ffffad;
    }
    100% {
    transform: scale(0);
    opacity: 0;
    }
}

.winwheel-element{
    z-index: 2;
    position: relative;
}

.reward-wheel-container{
    height: unset!important;
}

@media only screen and ( min-width:280px) and ( max-width:700px) {
    .rw-text {
        color: #fff;
        font-size: 12px;
        font-weight: 600;
    }
    .rw-pagination-btn.next svg, .rw-pagination-btn.prev svg {
        width: 30px;
    }
    .rw-my-points-card p {
        font-size: 13px;
        margin-bottom: 0;
        font-weight: 600;
    }
    .rw-my-points-card p span {
        font-size: 20px;
        font-weight: bold;
        vertical-align: middle;
    }
    .rw-merchant-name{
        font-size: 20px;
    }
    .sparkle-container {
        top: 0;
    }
    .reward-wheel-container{
        height: 102%!important;
    }
}

@media only screen and ( min-width:320px) and ( max-width:700px) {
    .rw-text {
        color: #fff;
        font-size: 13px;
        font-weight: 600;
    }
    .rw-pagination-btn.next svg, .rw-pagination-btn.prev svg {
        width: 12px;
    }
    .rw-my-points-card p {
        font-size: 15px;
        margin-bottom: 0;
        font-weight: 600;
    }
    .rw-my-points-card p span {
        font-size: 22px;
        font-weight: bold;
        vertical-align: middle;
    }
    .rw-merchant-name{
        font-size: 22px;
    }
    .sparkle-container {
        top: 0;
    }
    .reward-wheel-container{
        height: 102%!important;
    }
}

@media only screen and ( min-width:376px) and ( max-width:800px) {
    .rw-text {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }
    .rw-pagination-btn.next svg, .rw-pagination-btn.prev svg {
        width: 13px;
    }
    .rw-my-points-card p{
        font-size: 16px;
        margin-bottom: 0;
        font-weight: 600;
    }
    .rw-my-points-card p span{
        font-size: 24px;
        font-weight: bold;
        vertical-align: middle;
    }
    .rw-merchant-name{
        font-size: 25px;
    }
    .sparkle-container {
        top: 0;
    }
    .reward-wheel-container{
        height: 100%!important;
    }
}

@media only screen and (max-width: 992px) {
    .rw-margin-bottom{
        margin-bottom: 35px;
    }
}

@media only screen and ( min-width:800px) and ( max-width: 1140px) {
    .reward-wheel-container{
        height: 100%!important;
    }
}

/* CSS */
.custom-reward-wheel-button-to-wheel {
  align-items: center;
  appearance: none;
  background-color: #3EB2FD;
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd,sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.custom-reward-wheel-button-to-wheel:active,
.custom-reward-wheel-button-to-wheel:focus {
  outline: none;
}

.custom-reward-wheel-button-to-wheel:hover {
  background-position: -20px -20px;
}

.custom-reward-wheel-button-to-wheel:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}
</style>